@media screen and (max-width: 1000px) {
    .pastSponsorImg {
        width: 70%;
    }
}

@media (min-width: 1000px) {
    .pastSponsorImg {
        width: 100%;
    }
}