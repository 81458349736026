@import "slick-carousel/slick/slick.css"; 
@import "slick-carousel/slick/slick-theme.css";

#past-iterations-page {
  margin-top: 100px;
  height: 100%;
}

.slider{
  width: 100%;
  height: 100%;
}

.slide img{
  width: 130px;
  height: 130px;
}

.slide{
  width: 130px;
  height: auto;
  transform: scale(.7);
  transition: .5s;
  padding: 25px;
}

.slide-active{
  filter: blur(0);
  opacity: 1;
  transform: scale(1);
}

.arrow{
  font-size: 35px;
  padding: 5px 15px;
  border: none !important;
  /* border-radius: 10px; */
  /* box-shadow: 0 0 5px 3px #FC7269; */
  color: #FC7269;
  display: flex;
  /* width: 50px; */
  position: absolute;
  top: -30px;
  z-index: 100;
  cursor: pointer;
}

.arrow-right{
  right: -40px;
}

.arrow-left{
  left: -40px;
}

.dot{
  margin-top: 20px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: 0 0 5px 3px #ccc;
}

.dot-active{
  background: #121227;
  box-shadow: 0 0 15px 10px #ccc;
}

.carousel-line2{
    position: relative;
    width: 95%;
    height: 2px;
    transform: rotate(180deg);
    z-index: 0;
    background-image: linear-gradient(to right, #FC7269,#7F3BDF);
    top: 90px;

}

.year-label{
    background: #7F3BDF;
    width: 130px;
    height: 35px;
    border-radius: 20px;
    border: none;
    font-size: 14pt;
}

.year-label:hover{
  /* box-shadow: 0 5px 15px #7F3BDF; */
  background: #56018D;
  color: #fff;
  box-shadow: 0 0 5px #03e9f4,
                0 0 30px #03e9f4,
                0 0 20px #03e9f4,
                0 0 10px #03e9f4;
}

.has-link {
  cursor: pointer;
}


.pi-container{
    align-items: left;
    width: 65%;
    height: 100%;
}

.i-text-container {
  font-size: 15pt;
  padding-bottom: 40px;
}


@media (min-width: 1368px) {
  .pi-container {
      width: 80%;
  }
}

@media (max-width:1368px) {
  .pi-container {
    width: 100%
  }
}