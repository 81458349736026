@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Press+Start+2P&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

#about-page {
    height: 100%;
    
}

.container{
    align-items: left;
    left: 200px;
}

@media (min-width: 1368px) {
    .container {
       width: 60%;
    }
 }

.text-container{
    display: flex;
    width: 100%;
    text-align: left;
    padding-bottom: 40px;
    font-size: 15pt;
}

.i-text-container{
    display: flex;
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
}

.img{
    width: 100%;
}

.img-label{
    font-size: 12pt;
    text-align: right;
}

.carousel-icon{
    width: 120px;
    height: 120px;
}

.vertical-container{
    position: relative;
    /* margin-top: 70px; */
}

.button{
    border: none;
    background: #9059D8;
    border-radius: 24px;
    width: 128px;
    height: 35px;
    color: white;
    cursor: pointer;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 12pt;
    overflow:visible;
    /* margin-top:20px; */
    margin-bottom: 10px;
}

.button:hover {
    background: #03e9f4;
    color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 5px #9059D8,
                0 0 10px #9059D8,
                0 0 35px #FC7269,
                0 0 50px #FC7269;

}

.border{
    border: 5px solid #9059D8 !important;
    border-radius: 14px;

}

.carousel-line{
    position: relative;
    width: 95%;
    height: 2px;
    transform: rotate(180deg);
    z-index: 0;
    background-image: linear-gradient(to right, #FC7269,#7F3BDF);
    top: 68px;

}

.pi-carousel-container{
    width: 65%;
    display: block;
    overflow: visible;
}

