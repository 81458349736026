footer {
    box-sizing: border-box;
    background: #9059D8;
    width: 100%;
    padding: 20px;
    margin-top: 3%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icons {
    background-color:#9059D8;
    padding-left: 0px;
    margin-top: 10px;
}

.icons img {
    background-color: #9059D8;
    padding: 0 3px;
}

.mailing > p {
    font-size: 13pt;
}

.mail-address {
    font-size: 13pt;
    font-weight: 500;
}

.mail-address:hover{
    font-weight: 700;
}

.icons p{
    text-align: left;
    padding:3px;
}

.conduct {
    padding-right: 10px;
    background-color: #9059D8;
    display:flex;
    flex-direction: column;
}

.conduct p{
    color: white;
    background-color: #9059D8;
    text-align: right;
    padding: 3px;
}

.conduct a {
    text-decoration: none;
    color: white;
    background-color: #9059D8;
    text-align: right;
    padding: 3px;
    font-weight: 500;
    font-size: 13pt;
}

.conduct a:hover {
    font-weight: 700;
}

@media screen and (max-width: 800px){
    footer{flex-direction: column;}
    .icons{
        display:flex;
        flex-direction: column;
    }
    .contact-logos{
        display:flex;
        justify-content: center;
    }
   
    .icons p{
        text-align: center;
    }
    .conduct{
        margin:15px;
    }
    .conduct p{
        padding: 3px;
        text-align: center;
    }
}

