#press-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin-top: 80px;
}

.press-header {
    padding-bottom: 40px;
    width: 100%;
}

.curious-text {
    font-weight: normal;
    text-align: left;
    font-size: 15pt;
    cursor: pointer;
}

.article-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.press-box {
    width: 260px;
}

.box {
    width: 250px;
}

.img-box {
    width: 100%;
    height: 120px;
    object-fit: cover;
}

.purple-box {
    background-color: blueviolet;
    height: 190px;
    margin-top: -10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.article-title {
    font-weight: bold;
    margin-top: 20px;
}

.article-title, .published-text, .article-link {
    font-size: 13pt;
    padding-bottom: 15px;
}

.article-link:hover {
    text-decoration: underline;
}

@media (max-width: 700px) {
    .article-container {
        flex-direction: column;
    }
}

@media (min-width: 1368px) {
    .press-box {
        width: 235px;
    }
}

@media (min-width: 1368px) and (max-width: 1490px) {
    .press-box {
        width: 215px;
    }
}

@media (min-width: 1368px) {
    #press-page {
        width: 60%;
    }
}