.hamburger-menu {
  width: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 5px;
}

.hamburger-line {
  width: 100%;
  height: 5px;
  background-color: #fff;
  transition: transform 0.2s ease-in-out;
  border-radius: 10px;
  margin-bottom: 10px;
}

.activeLine {
  position: absolute;
}

.hamburger-menu.active .hamburger-line:first-child {
  transform: rotate(45deg);
}

.hamburger-menu.active .hamburger-line:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.active .hamburger-line:last-child {
  transform: rotate(-45deg);
}

@media (max-width: 320px) {
  .hamburger-menu {
    width: 35px;
  }

  .hamburger-line {
    margin-bottom: 8px;
  }
}
