.mainContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
  animation: fadeIn 0.7s;
}

.patterns {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    animation: infinite-rotation 200s infinite;  
}

.patterns:nth-child(1) {
  transform: rotate(70deg);
}

.patterns:nth-child(2) {
  transform: rotate(0deg);
}

.patterns:nth-child(3) {
  transform: rotate(200deg);
}



.pattern {
  position: inherit;
  display:block;
  width: 250px;
  height: 250px;
  margin-top: -125px;
  margin-left: -125px;
  opacity: 0.3;
}

.pattern:nth-child(1) {
    top: 78%;
    bottom: 56%;
    right: 80%;
}

.pattern:nth-child(2) {
    top: 80%;
    bottom: 50%;
    right: 80%;
}

.pattern:nth-child(3) {
    top: 12%;
    left: 24%;  
}

.pattern:nth-child(4) {
    display:block;
    top: 40%;
    left: 90%;
    transform: rotate(50deg);    
}

.pattern:nth-child(5) {
    top: 80%;
    bottom: 50%;
    left: 80%;
}

.pattern:nth-child(6) {
  top: 60%;
  bottom: 50%;
  left: 80%;
}

.pattern:nth-child(7) {
  top: 80%;
  bottom: 80%;
  left: 80%;
}

.pattern:nth-child(8) {
  top: 90%;
  bottom: 50%;
  left: 50%;
}

.pattern:nth-child(9) {
  top: 30%;
  bottom: 50%;
  left: 50%;
}

.pattern:nth-child(10) {
  top: 30%;
  bottom: 20%;
  left: 30%;
}

.pattern:nth-child(11) {
  top: 30%;
  bottom: 20%;
  left: 30%;
  }
  .pattern:nth-child(12) {
  top: 60%;
  bottom: 40%;
  left: 70%;
  }
  .pattern:nth-child(13) {
  top: 15%;
  bottom: 10%;
  left: 50%;
  }
  .pattern:nth-child(14) {
  top: 85%;
  bottom: 70%;
  left: 25%;
  }
  .pattern:nth-child(15) {
  top: 50%;
  bottom: 35%;
  left: 15%;
  }
  .pattern:nth-child(16) {
  top: 95%;
  bottom: 80%;
  left: 45%;
  }
  .pattern:nth-child(17) {
  top: 25%;
  bottom: 10%;
  left: 75%;
  }
  .pattern:nth-child(18) {
  top: 65%;
  bottom: 55%;
  left: 10%;
  }
  .pattern:nth-child(19) {
  top: 80%;
  bottom: 60%;
  left: 35%;
  }
  .pattern:nth-child(20) {
  top: 40%;
  bottom: 25%;
  left: 90%;
  }

@keyframes infinite-rotation {
    from {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        transform: rotate(0deg);
   }
    to {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
   }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
