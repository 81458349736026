.sponsor-flexbox-container {
  display: flex;
  justify-content: center;
  margin: 0;
  gap: 5%;
  color: aliceblue;
  height: 100%;
  margin-top: 110px;
}

.column {
  text-align: left;
  width: 46%;
}

.sponsor-header {
  font-weight: 700;
  font-size: 30pt;
  margin: 0;
  padding-bottom: 40px;
}

.sponsor-description {
  font-size: 15pt;
  padding-bottom: 30px;
}

@media screen and (max-width: 1000px) {
  .sponsor-flexbox-container {
    flex-direction: column;
    width: 100%;
    gap: 0px;
  }

  .column {
    width: 100%;
  }
}

@media (min-width: 1368px) {
  .sponsor-flexbox-container {
     width: 60%;
  }
}
