@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Press+Start+2P&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

#gallery-page {
   margin-top: 100px;
   height: 100%;
}

.title-text {
   font-weight: 700;
   font-size: 30pt;
   padding-bottom: 40px;
   text-align: left;
}

.description {
   font-size: 15pt;
   text-align: left;
   padding-bottom: 40px;
}

.gallery-slider {
   width: 100%;
   height: 500px;
   margin: 0 auto;
   object-fit: "cover";
   aspect-ratio: auto;
}

@media (min-width: 1368px) {
   #gallery-page {
      width: 60%;
   }
}