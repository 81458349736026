#past-winners-page {
    margin-top: 100px;
    height: 100%;
}

.winner-box {
    width: 270px;
}

.winner-desc {
    background-color: blueviolet;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.winner-img {
    width: 100%;
    height: 170px;
    object-fit: cover;
}

.project-link {
    font-weight: bold; 
    font-size: 25px;
    text-decoration: underline;
    margin-top: 15px;
}

.devpost-link {
    font-size: 13pt;
}

.devpost-link:hover,
.project-link:hover {
    text-decoration: underline;
}

.custom-arrow {
    position: absolute;
    top: 20%;
}

.category {
    font-style: italic;
    font-size: 18px;
}

@media (max-width: 468px) {
    .winner-box {
        width: 225px;
    }

    .category {
        font-size: 16px;
    }

    .devpost-link {
        font-size: 11.5pt;
    }
}

@media (max-width: 380px) {
    .winner-box {
        width: 200px;
    }

    .devpost-link {
        font-size: 10pt;
        padding: 0 5px;
    }
}

@media (max-width: 350px) {
    .winner-box {
        width: 180px;
    }

    .devpost-link {
        font-size: 11pt;
    }
}

@media (max-width: 330px) {
    .winner-box {
        width: 165px;
    }

    .category {
        font-size: 15px;
    }

    .devpost-link {
        font-size: 11pt;
    }
}

@media (min-width: 1020px) and (max-width: 1300px) {
    .winner-box {
        width: 250px;
    }
}

@media (min-width: 1368px) {
    .winner-box {
        width: 260px;
    }
}

@media (min-width: 1800px) {
    .winner-box {
        width: 275px;
    }
}

