*,
*::before,
*::after {
  box-sizing: border-box;
}

.img-slider-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.img-slider-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  position: absolute;
  transition: opacity 0.5s ease-in-out;
}

.img-slider-img.active {
  opacity: 1;
}

.img-slider-btn {
  all: unset;
  display: block;
  font-size: 35px;
  padding: 1rem;
  border: none !important;
  color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 100;
  cursor: pointer;
  transition: background-color 100ms ease-in-out;
}

.img-slider-btn:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.img-slider-btn > * {
  stroke: white;
  fill: white;
  width: 2rem;
  height: 2rem;
}

.caption {
  margin-top: 0.6rem;
  font-size: 12pt;
  text-align: right;
}
