@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");

header {
    align-items: center;
    text-align: center;
    color: aliceblue;
    font-family: Poppins, sans-serif;
    z-index: 2;
    margin-top: 15%;
    height: 90vh;
}

hero-page h2 {
    margin-bottom: 23vh;
}

.sign-up-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sign-up-button{
    font-weight: 500;
    background: #7F3BDF;
    width: 250px;
    height: 48px;
    border-radius: 24px;
    border: none;
    margin:1vh;
    transition: 0.2s;
    cursor: pointer;
}

.sign-up-button:hover {
  background: #56018D;
  color: #fff;
  transition: 0.2s;
  box-shadow: 0 0 5px #03e9f4,
              0 0 25px #03e9f4,
              0 0 50px #03e9f4,
              0 0 100px #03e9f4;
}

.top-title {
    font-size: 80px;
    font-weight: 400;
    text-shadow: 0px 0px 8px #9b51e0;
    font-family: "Press Start 2P", sans-serif;
    z-index: 2;
}

.top-title-container {
  text-align: center;
  margin-top: 28vh;
  margin-bottom: 12vh;
}

.nyu-purple {
    color: var(--nyuPurple);
    padding-top: 5px;
    z-index: 2;
}

.subtitle {
    position: relative;
    z-index: 2;
    font-size: calc(14px + 0.8vw);
    margin: 0 5px;
}

.hero-buttons-container {
    display: flex;
    /* flex-direction: column; */
    height: 15vh;
    justify-content: space-around;
    margin-top: 5vh;
    margin-left: 10%;
    margin-right: 10%;
}

h1 {
    font-weight: 400;
    font-size:calc(19px + 1.5vw);
    position: relative;
    z-index: 2;
    margin: 0 5px;
}

.arrow {
  margin-top: 6vh;
  margin-bottom: calc(3px+0.5vw);
  animation: move-up-down 1.8s linear infinite;
}

.arrow:hover {
    animation: 1.8s boom, 1.8s move-up-down linear infinite;
    transition: all 0.2s ease-in-out;
}

.heroArrow {
    position: relative !important;
    display: inline !important;
}

.top-title-container span {
    font-size: 10vw;
}

@keyframes move-up-down {
    0%, 100% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(8px);
    }
}

@media (max-width: 1050px) {
    header {
        margin-top: 10vh;
    }

    .hero-buttons-container {
        flex-direction: column;
    }
}

@media (max-width: 400px) {
    h1 {
        font-size: calc(15px + 1.5vw);
    }

    .subtitle {
        font-size: 13.5pt !important;
    }

    .arrow {
        margin-top: 7vh;
        width: 18%;
    }
}