a,
button {
  font-size: 16px;
  color: white;
  text-decoration: none;
  font-weight: 500;
}

.navbar {
  position: fixed;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  z-index: 3;
}

@media (max-width: 1090px) {
  .navbar {
    flex-direction: column;
  }
}

/* PREVIOUSLY WAS `a` TAG */
.menu-item {
  display: inline;
  margin: 10px;
  transition: 0.1s;
  cursor: pointer;
  font-size: 17.5px;
}

.menu-item:hover {
  color: #9059d8;
  transition: 0.1s;
}

.left-section {
  position: absolute;
  left: 0;
  margin-left: 20px;
  margin-top: 10px;
}

.logo {
  height: 100%;
  width: 100%;
}

#mlh-logo {
  display: block;
  width: 100px;
  position: fixed;
  right: 80px;
  z-index: 10000;
}

.right-section {
  position: absolute;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media (min-width: 1090px) {
  .right-section {
    display: none;
  }

  #mlh-logo {
    right: 20px;
  }
}

@media (max-width: 640px) {
  .logo {
    height: 85%;
    width: 85%;
  }

  #mlh-logo {
    width: 90px;
  }
}

@media (max-width: 320px) {
  .logo {
    height: 78%;
    width: 78%;
  }

  #mlh-logo {
    width: 80px;
  }
}

@media (max-width: 1090px) {
  .menu {
    position: absolute;
    right: 20px;
    top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    background-color: black;
    border-radius: 10px;
    box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.1),
      0 0 0 1px hsla(230, 13%, 9%, 0.075),
      0 0.3px 0.4px hsla(230, 13%, 9%, 0.02),
      0 0.9px 1.5px hsla(230, 13%, 9%, 0.045),
      0 3.5px 6px hsla(230, 13%, 9%, 0.09);
  }

  .invisibleInMobile {
    display: none;
  }
}
