#faq-page {
  margin-top: 100px;
  height: 100%;
}

.faq-header{
  font-size: 30pt;
  font-weight: 700;
  border-bottom: 1px dotted #ccc;
  padding-bottom: 40px;
  font-family: 'Poppins', sans-serif;
}

.faq-content {
  margin: 0 auto;
}

.faq-question {
  padding-top: 30px;
  padding-bottom: 23px;
  border-bottom: 1px dotted #ccc;
}

.panel-title {
  font-size: 15pt;
  width: 89%;
  position: relative;
  margin: 0;
  padding: 10px 10px 0 10px;
  display: block;
  cursor: pointer;
  font-family: "Press Start 2P";;
  line-height: 150%;
}

.panel-content {
  font-size: 16pt;
  padding: 0px 14px;
  margin: 20px 50px 0 40px;
  height: 0;
  z-index: 3;
  opacity: 0;
  -webkit-transition: .4s ease;
  -moz-transition: .4s ease;
  -o-transition: .4s ease;
  transition: .4s ease;
}

.plus {
  position: absolute;
  margin-left: 20px;
  margin-top: 4px;
  z-index: 2;
  font-size: 42px;
  line-height: 100%;
  -webkit-transition: .2s ease;
  -moz-transition: .2s ease;
  -o-transition: .2s ease;
  transition: .2s ease;
  right: 10%;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.plus.active {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.plus.active ~ .panel-content {
  height: auto;
  opacity: 1;
  padding: 14px;
}

.panel {
  display: none;
}

.faq-link {
  font-size: 15pt;
  cursor: pointer;
  text-decoration: underline;
  z-index: 5;
}

.faq-title {
  color: white; 
  position: relative;
  margin-bottom: 45px;
  display: inline-block;
  font-weight: 600;
  line-height: 1;
}

@media (max-width: 540px) {
  .panel-title {
    font-size: 12pt;
  }

  .panel-content, .faq-link {
    font-size: 11pt;
  }

  .faq-title {
    font-size: 20pt;
  }
}