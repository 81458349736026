@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --nyuPurple: #9B51E0;
}

body {
  color: white;
  background-color: black;
  font-family: 'Poppins', sans-serif;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.withAnimation {
  animation: 0.15s boom infinite;
}

.withSlowAnimation {
  animation: 2s boom linear infinite;
  transition: all 0.2s ease-in-out;
}

.glow {
  filter: drop-shadow(-4px 0px 5px red) drop-shadow(4px 0 5px cyan);
}

@keyframes boom {
    from {
        filter: drop-shadow(-8px 0 5px red) drop-shadow(8px 0 5px cyan);
    } to {
        filter: none;
    }
}

@keyframes reverseBoom {
    to {
        filter: drop-shadow(-8px 0 5px red) drop-shadow(8px 0 5px cyan);
    }
}

.fill-window {
    height: 100%;
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
}

.body-section{
    margin: 7%;
}

.glowInside {
    color: #fff;
  font-size: 100px;
  text-shadow: 0 0 50px #0f0, 0 0 100px #0f0, 0 0 150px #0f0, 0 0 200px #0f0, 0 0 250px #0f0;
  animation: glowRotate 5s linear infinite;
}

.glowOutside {
  font-size: 100px;
  text-shadow: 0 0 50px #0f0, 0 0 100px #0f0, 0 0 150px #0f0, 0 0 200px #0f0, 0 0 250px #0f0;
  animation: glowRotate 5s linear infinite;
}

@keyframes glowRotate {
    0% {
      filter: hue-rotate(0deg);
    }
    100% {
      filter: hue-rotate(360deg);
    }
  }

.title {
    font-weight: 700;
    font-size: 30pt;
    text-align: left;
    padding-bottom: 40px;
}

  
@media (max-width: 512px) {
  .title {
    font-weight: 700;
    font-size: 24.5pt !important;
  }
}

@media (max-width: 380px) {
  .title {
    font-weight: 700;
    font-size: 23pt !important;
  }
}

@media (max-width: 330px) {
  .title {
    font-weight: 700;
    font-size: 24pt !important;
  }
}
