.glowButton div {
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #03e9f4;
    /* font-size: calc(13px + 0.5vw); */
    font-size: calc(18px + 0.5vw);
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    /* margin-top: 40px; */
    letter-spacing: 4px;
  }
  
  .glowButton div:hover {
    /* background: #03e9f4; */
    background: #56018D;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #03e9f4,
                0 0 25px #03e9f4,
                0 0 50px #03e9f4,
                0 0 100px #03e9f4;
  }
  
  .glowButton div span {
    position: absolute;
    display: block;
  }
   
  .glowButton div span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #03e9f4);
    animation: btn-anim1 1s linear infinite;
  }

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

.glowButton div span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

.glowButton div span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

.glowButton div span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}

@media (min-width: 1368px) {
    .glowButton div {
        font-size: calc(21px + 0.5vw);
    }
}

@media (max-width: 468px) {
    .glowButton div {
        font-size: calc(17px + 0.5vw);
    }
}
